.block-categories {
  box-shadow:$card-box-shadow;;
}



//block categories
.block-categories {
  a{
    color:$body-color;
  }

  li.chosen {

    >div>a{
      font-weight: $weight-bold;}
  }

  .first-line {
    background-color: transparentize($white, 0.75);
    &:hover {
      background-color: transparentize($white, 0.6);
    }
    >a {
      flex:1;
    }
  }


  .collapse-icons {
    width: 3rem;
    img {
      top:0
    }
  }

  .first-line.bg-primary > a{
      color:$white;
    }

  .first-line.bg-primary > i{
      display:none}
    
  }

.first-line.bg-primary >div>.close {
  display: none;
}

.first-line:not(.bg-primary) >div>.open {
  display: none;
}

.category-sub-link {
  position: relative;
    padding-left: 0.5rem;
    &:before {
      content: " ";
      width: 0.25rem;
      height: 100%;
      position: absolute;
      left: -0.25rem;
      background: url(https://purtex-matrace.sk/themes/purtex/assets/img/arrowright.svg) no-repeat;
      top: 33%;
    }
}
.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}

.collapse-icons {
  width: $spacer;
  height: $spacer;
  img {
    right: 0;
    top: $spacer/2;
  }
}


.featured-categories {
  margin-bottom: $spacer * 4;
  .row {
    row-gap: $spacer * 2.5;
  }
  .category-item > .card {
    @include transition-fast;
    a {
      @include transition-fast;
    }
    &:hover {
      box-shadow: $box-shadow-lift-up;
      transform: translateY(-0.25rem);
      a {
        color: $primary !important
      }
    }
  }
  .category-thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: 20rem;
      padding: 0 ($spacer * 1.5);
      object-fit: scale-down;
    }
  }
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $h3-font-size;
    }
  }
}