header {
  position: sticky;
  top: -1px;
  z-index: 21;
  flex-wrap: wrap;
  @include transition-fast;
  .is-stuck {
    -webkit-box-shadow: 0px 7px 15px -8px rgba(173, 173, 173, 0.58);
    
  }}

  @include media-breakpoint-down(xs) { 
.header__logo img.logo {
  max-width: $spacer*6;
}}
  // @include media-breakpoint-down(xl) {
  //   .header__logo,
  //   .header__logo--checkout {
  //     max-width: 50%;
  //   }
  //   .header__logo--checkout {
  //     margin: 0 auto;
  //   }

  //   .header__right {
  //     flex-grow: 1;
  //   }

  //   .header__rightitem.mobile-menu-hide {
  //     display: none;
  //   }
  // }

  /* Header Top Nav */

  .header-nav__left {
    span,
    a:not(.dropdown-item) {
      color: $text-gray;
    }
  }
@media (min-width:1601px) {
    .megamenu-mobile-toggle {
      display: none;
    }
  }



  .header-nav__right,
  .footer-bar__right {
    background-color: $header-nav-right-bg-color;
    @include transition-fast;
    svg {
      @include transition(inherit);
    }
    a {
      @include transition(inherit);
    }
    &:hover {
      background-color: darken($header-nav-right-bg-color, 5%);
      a {
        transform: translateY(-($spacer * 0.0625));
        svg {
          color: inherit;
        }
      }
    }
  }

  .header-nav__right a {
    font-weight: $font-weight-bolder;
    letter-spacing: 1px;
  }

  .header__logo--checkout {
    max-width: 200px;
  }
  .header-nav--checkout {
    padding-top: $spacer;
    padding-bottom: $spacer;
  }
  @include media-breakpoint-down(xl) {
    .megamenu-mobile-toggle {
      cursor: pointer;
      z-index: 999999;    
      display: flex;
      align-items: center;
      svg {
        line {
          transform-origin: center;
          @include transition-fast;
        }
        &.megamenu-open {
          line {
            &:nth-of-type(1) {
              opacity: 0;
            }
            &:nth-of-type(2) {
              transform: rotate(45deg) translateY(25%);
            }
            &:nth-of-type(3) {
              transform: rotate(-45deg) translateY(-25%);
            }
          }
        }
      }
      &:hover,
      &:active,
      &:focus {
        svg {
          color: $primary;
        }
      }
    }


  }

  .header-top {
    background: $backround-color;
    // z-index: 9999999;
  }

  .top-banner p {
    background: $primary;
    border-radius: $border-radius-lg;
    color:$white;
    }

