.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: unset;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.05);
	border-radius: 10px;
	background-color: white;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
	background-color: $secondary;
}

.breadcrumb{
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: transparent
  }
  
  &::-webkit-scrollbar
  {
    width: 3px;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: transparent;
  }

}



.hidden {
  display: none;
}
.fw-black {
  font-weight: $weight-black;
}
.fw-bold {
  font-weight: $weight-bold;
}

.fw-med {
  font-weight: $weight-medium;
}

.fw-reg {
  font-weight: $weight-regular;
}

.f-color {
  color: $font-color;
}

.f-color2 {
  color: $primary;
  fill: $primary;
}

.fs-2 {
  font-size: $display2-size;
}

.fs-3 {
  font-size: $display3-size;
}

.fs-4 {
  font-size: $display4-size;
}

.fs-sm {
  font-size: $small-font-size;
  line-height: 1.6;
}

.fs-xs {
  font-size: $font-size-base * 0.75;
}

.bg-sec {
  background-color: $tertiary;
}

.big-rad {
  border-radius: $btn-radius;
}

.sm-rad {
  border-radius: $border-radius-lg;
}

.g-1 {
  gap: $spacer;
}

.g-2 {
  gap: $spacer * 2;
}

img:not(.img-cover):not(.img-contain) {
  max-width: 100%;
  height: auto;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid-2,
.grid-3,
.grid-4,
.grid-5 {
  display: grid;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

@include media-breakpoint-down(lg) {
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media-breakpoint-down(md) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include media-breakpoint-down(sm) {
  .grid-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-down(xs) {
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.alert-warning {
  background-color: $tertiary;
  border-color: $tertiary;
  color: $primary;
}
.alert-info {
  background-color: $secondary;
  border-radius: $btn-radius;
  color: $primary;
}

[data-link] {
  cursor: pointer;
}

#category-description, #category-description-full, #sdsblogArticle {
  ul {
    padding-left: $spacer;
    list-style: disc;
    padding-bottom: $spacer;
  }

  .customAlert {
    background-color: $primary;
    color: $white;
    padding: $spacer*1.75;
    border-radius: $border-radius-lg;
    a {
      color:$white
    }
  }

  a {
    text-decoration: underline;
  }
  
}

#category-description-full {
  display: none;
}

#carousel {
  height: 100%;
  max-height: auto;
  @include transition-slow;
  &::before {
    content: "";
    background: url(/themes/purtex/assets/img/loader.svg) no-repeat center;
    background-size: $spacer * 4;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:not(.slick-initialized) {
    max-height: 512px;
    @include transition-slow;
    .carousel-image {
      visibility: hidden;
      @include transition-slow;
    }
  }
  &.slick-initialized {
    max-height: auto;
    .carousel-image {
      visibility: auto;
    }
  }

  .img-carousel {
    min-height:450px;
  }
}

.table {
  background-color: transparent;
  .table td {
    border: none;
  }
}
// Button
select,
button:not(.js-touchspin):not(.btn-modal),
input:not([type="checkbox"]):not([type="radio"]):not(
    .js-cart-line-product-quantity
  ),
.custom-file,
.custom-file-input,
.custom-file-label,
.custom-file-label:after,
.custom-select {
  min-height: $spacer * 3.125;
  height: unset;
}

.form-control,
.form-control[type="email"],
.form-control[type="text"],
.custom-file-input,
.custom-file-label,
.custom-file,
.custom-select {
  border: none;
}
.custom-file-label:after {
  display: flex;
  align-items: center;
}
a:hover {
  color: $primary;
}
.btn {
  padding: $spacer;
  position: relative;
  overflow: hidden;
  z-index: 10;
  border: none;
  border-radius: $btn-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacer * 0.25;
  @include transition-fast;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-primary {
  &.bt-white:hover {
    color: $primary;
  }
  svg {
    width: $btn-icon-size;
    height: $btn-icon-size;
    opacity: $btn-icon-opacity;
  }
}

.btn-inner {
  border-radius: 0 $border-radius-lg $border-radius-lg 0;
}

.btn-white {
  background: $white;
  color: $font-color;
  &:hover {
    color: $white;
  }
}

.btn-outline-primary {
  border: solid 1px $primary;
  color: $primary;
  svg {
    width: $btn-icon-size;
    height: $btn-icon-size;
    opacity: $btn-icon-opacity;
  }
}

.card {
  border: none;
}

.card-body {
  position: relative;
  @include media-breakpoint-mobile(up) {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
  @include media-breakpoint-mobile() {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
}

// Form

.form-control {
  height: unset;
  box-shadow: none;
  @include border-radius($border-radius-lg);
  border-color: lighten($primary, 25%);
  &:focus,
  &:active {
    border-color: $primary;
  }
}
.custom-select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $primary 50%),
    linear-gradient(135deg, $primary 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1.2rem + 2px),
    calc(100% - 15px) calc(1.2rem + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

input[type="radio"]:checked {
  background-color: $primary;
}
input[type="checkbox"]::before {
  box-shadow: inset 1em 1em $primary;
}
input[type="checkbox"]:focus {
  outline-color: $primary;
}

.form-control,
.form-control[type="email"],
.form-control[type="text"] {
  border-color: $gray-300;
}

input:focus-visible {
  outline: none !important;
  border: none;
}

// blog
.sdsarticleItem > div,
.sdsarticleItem .articleContent {
  height: 100%;
}

.post_image {
  img {
    width: 100%;
  }
}

h2.sdsarticleTitle {
  font-size: $font-size-base;
}

// aboutus
.o-firme-uvod p a img {
  width: 100%;
  height: auto;
}

.about-wrapper {
  display: flex;
  justify-content: space-between;
  .tablinks {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin: 0 2rem 1rem 0;
    cursor: pointer;
    .tabicon img {
      width: 40px;
      height: auto;
      filter: grayscale(1);
    }
    &.active .tabicon img {
      filter: grayscale(0);
    }
  }

  .about-contents {
    flex: 1 0 90%;
  }

  .tabcontent {
    display: none;
    &.active {
      display: block;
    }
  }
}

@media only screen and (max-width: 764px) {
  .page-content.page-cms iframe {
    max-width: 100%;
  }

  .about-wrapper {
    flex-direction: column;
  }

  .about-wrapper .about-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// contact

.contact-rich i.material-icons {
  font-size: $font-size-base * 1.375;
}

/* custom toggles */
.toggles {
  margin-bottom: 4rem;
  cursor: pointer;
}

.toggleTitle {
  font-weight: $weight-bold;
  background: $white;
  padding: $spacer $spacer * 2.5 $spacer $spacer;
  position: relative;
  border-radius: $border-radius-lg;
  margin-top: $spacer;
}

.toggleContent {
  padding: 1rem;
}

.toggleContent ul {
  margin-left: 1.5rem;
}

.symbol {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

/* endof custom toggles */

.toggle.prodejna {
  margin-bottom: 10px;
}

.toggle-head {
  position: relative;
  padding: 14px;
  border-top: 1px solid #f2f2f2;
}

.toggle-head.active {
  border-bottom: 1px solid #f2f2f2;
  background: #f2f2f2;
}

.toggle-head:hover {
  background: #f2f2f2;
}

.toggle h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #222;
}

.toggle-body {
  display: none;
  padding: 14px;
  >div {
    float: left;
  }
}

.toggle-body.active {
  display: block;
  height: 240px;
}

.toggle-head i {
  position: absolute;
  top: 17px;
  right: 14px;
  color: #222;
}

.toggle.active i.add {
  display: none;
}

.toggle.dozor .toggle-body.active {
  height: auto;
}

.toggle.prodejna .toggle-head {
  background-color: #f2f2f2;
}

.toggle.prodejna.active .toggle-head,
.toggle.prodejna:hover .toggle-head {
  background-color: #00468e;
}

.toggle.prodejna.active .toggle-head h3,
.toggle.prodejna.active .toggle-head i,
.toggle.prodejna:hover .toggle-head h3,
.toggle.prodejna:hover .toggle-head i {
  color: #fff;
}

.toggle.prodejna .toggle-head {
  border-radius: 5px;
  cursor: pointer;
}

.toggle.prodejna.active .toggle-head {
  border-radius: 5px 5px 0 0;
}

.toggle.prodejna .toggle-body {
  background: #f2f2f2;
  border-radius: 0 0 5px 5px;
  a:hover {
    text-decoration: underline;
  }
}

.toggle.prodejna .bttn {
  padding: 10px 20px;
  background: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: $border-radius-lg;
  margin-top: 5px;
  display: inline-block;
}

.toggle.prodejna .bttn:hover {
  color: #fff;
  background: #00468e;
  text-decoration: none;
}

// guest tracking
#guestOrderTrackingForm .alert p:last-child {
  margin-bottom: 0;
}

// recenze
img#heureka-logo {
  left: calc(50% - 68px);
  top: -55px;
}
.recenze-carousel .slick-track {
  display: flex;
  align-items: stretch;
}

.recenze-carousel .slick-slide > div,
.recenze-carousel .hodnoceni {
  min-height: $spacer * 15;
}
.recenze-carousel .hodnoceni {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

#heureka_only_desktop .rating {
  max-height: $spacer * 1.5;
}

.recenze-carousel ul.slick-dots {
  display: flex;
  justify-content: center;
}

.recenze-carousel ul.slick-dots li {
  width: $spacer * 2;
  height: $spacer/2;
  border-radius: $spacer;
  margin-right: $spacer/2;
  background-color: $secondary;
  overflow: hidden;
  &.slick-active {
    background-color: $primary;
  }
}

.recenze-carousel ul.slick-dots li button {
  border: 0;
  font-size: 0;
  min-height: unset;
}

.recenze-carousel button.slick-prev.slick-arrow,
.recenze-carousel button.slick-next.slick-arrow {
  position: absolute;
  top: 10%;
  left: -4rem;
}

.recenze-carousel button.slick-next.slick-arrow {
  left: unset;
  right: -4rem;
}

/* cookie lišta respo fix */
.lgcookieslaw-reject-all-button-enabled .lgcookieslaw-button-container {
  flex-wrap: wrap;
}

.lgcookieslaw-button-container button,
.lgcookieslaw-button-container a {
  margin: 0.25rem !important;
}

@media (max-width: 1370px) {
  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-message {
    width: calc(50% - 0.5rem);
  }

  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-button-container {
    width: 50%;
  }
}

@media (max-width: 1120px) {
  .lgcookieslaw-button-container button,
  .lgcookieslaw-button-container a {
    width: 100% !important;
    display: block;
  }

  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-message {
    width: calc(60% - 0.5rem);
  }

  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-button-container {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-message,
  .lgcookieslaw-reject-all-button-enabled .lgcookieslaw-button-container {
    width: 100% !important;
  }
}


/* glossary */
.letter-icon-col {
  max-width: max-content;
}

.pstg_letter_row a{
  &:hover {
    color: $primary;
  }
}

#module-pstglossary-item {
  h2 {
    margin: $spacer*2.25 0 $spacer/2;
    font-weight: $weight-medium;
  }

  a {
    font-weight: $weight-medium;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* student */
.student-wrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  background-size: contain;
  background-image: url(/img/student_room.jpg);
  background-position: top;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.student-wrapper:after {
  padding-top: 56.23%;
  display: block;
  content: "";
}

.student-main {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: black;
  text-align: center;
  margin-top: 5%;
}

.table-student,
.night-table-student,
.bed-student,
.closet-student {
  position: absolute;
  z-index: 9;
  color: #fff;
  background: #00468e;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
  transition: 0.9s all;
  transform: scale(1);
  animation: pulse 2s infinite;
}

.table-student:hover,
.night-table-student:hover,
.bed-student:hover,
.closet-student:hover {
  transform: scale(1.1);
  transition: 0.9s all;
  animation: auto;
}

.table-student {
  top: 53%;
  left: 12%;
}

.night-table-student {
  top: 63%;
  left: 40%;
}

.bed-student {
  top: 65%;
  left: 68%;
}

.closet-student {
  top: 46%;
  left: 82%;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

@media (max-width: 550px) {

  .table-student,
  .night-table-student,
  .bed-student,
  .closet-student {
    position: absolute;
    z-index: 9;
    color: #fff;
    background: #00468e;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
    cursor: pointer;
    transition: 0.9s all;
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  .table-student {
    top: 47%;
    left: 12%;
  }

  .night-table-student {
    top: 59%;
    left: 40%;
  }

  .bed-student {
    top: 65%;
    left: 68%;
  }

  .closet-student {
    top: 46%;
    left: 82%;
  }
}

@media (max-width: 400px) {

  .table-student,
  .night-table-student,
  .bed-student,
  .closet-student {
    position: absolute;
    z-index: 9;
    color: #fff;
    background: #00468e;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
    cursor: pointer;
    transition: 0.9s all;
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  .table-student {
    top: 45%;
    left: 7%;
  }

  .night-table-student {
    top: 55%;
    left: 40%;
  }

  .bed-student {
    top: 58%;
    left: 68%;
  }

  .closet-student {
    top: 46%;
    left: 82%;
  }
}



/* cms */
.product-description {
  .row {
    padding: $spacer*2.5 0;
    @include media-breakpoint-down(md) {
      padding: $spacer*1.75 0;
    }
    @include media-breakpoint-down(sm) {
      &.reversed {
        flex-direction: column-reverse;
      }
    }
    > div {
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        &.minheight {
          min-height: 350px;
        }
      }
    }

    h2 {
      font-size: $display3-size;
      margin: 0 0 $spacer*1.25;
    }

    p:last-child {
      margin-bottom: 0;    }
  }

   

  ul {
    list-style: disc;
  }

  .img-cover {
    position: absolute;
    @include media-breakpoint-down(lg) {
      position: relative;
    }
  }

  .advantages { 
    text-align: center;
  }
}