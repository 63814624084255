.products {
    align-items: stretch;
}

/* Product miniature*/
.product-miniature {
    margin-bottom: $spacer * 2.5;
    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 1;
    }
}

.card-product {
    height: 100%;
    position: relative;
    background-color: $product-miniature-bg-color;
    border-width: $product-miniature-border-width;
    border-color: $product-miniature-border-color;
    .card-body {
        justify-content: space-between;
    }
    .discount {
        display: none;
    }

}

.card-img-top {
    overflow: hidden;
    .product-thumbnail img {
        width: 100%;
    }
}

.product-flags {
    position: absolute;
    top: 0;
    z-index: 1;
    left: -$spacer/4;
}
.page-content--product .product-flags {
    left: 0;
}
.product-flag {
    top: $spacer*2;
    left: $spacer*2;
    background: $secondary;

    &.discount-product,
    &.free-delivery  {
        background: $primary;
        right: -$spacer*2;
        left: unset;
        &::before {
                content: "";
                width: $spacer*.75;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-right: dashed 1px white;
            
        }
        @media (max-width:1880px) {
            right: $spacer*2
            
        }
    }

    &.free-delivery {
        top:3px
    }
}
.color {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-size: contain;
}

.product-title {
    & a {
        color: $product-miniature-name-color;
        &:hover {
            color: $product-miniature-name-hover-color;
        }
    }
    .card-product:hover & {
        color: $product-miniature-name-hover-color;
        & a {
            color: $product-miniature-name-hover-color;
        }
    }
}

/* PRODUCT PAGE */

#product .position-sticky {
    top:$spacer*7
}

.product-img:not(:first-child) .rc {
    display: none;
}

.product-img img {
min-height: 416px;
aspect-ratio: 1 / 1;
}

.product-thumbs {
    width: 20%;
    min-height: 100%;
    margin-right: $spacer;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

}
.product-thumb {
    margin: $product-thumb-margin;
    width: $product-thumb-width;
    height: $product-thumb-height;
    border: 2px solid transparent;
}

.products-imagescover-wrapper {
    width: 80%;  
    &.full {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        height:auto;
        width: 100%;
    }
}

.products-imagescover-wrapper, .modal-content #js-slick-product {
    .slick-track {
        display: flex;
    }

    .slick-slide {
       height: auto;
    }
    .slick-slide >div {
        height: 100%;
     }
}

.products-imagescover-wrapper .btn-zoom {
    position: absolute;
    right: $spacer;
    bottom: $spacer;
    cursor: pointer;
    display: none;
    padding: 0;
    line-height: 1;
    & .material-icons {
        font-size: 3 * $font-size-base;
    }
}
.images-container {
    max-height: 840px;
    &:hover .btn-zoom {
        display: block;
    }
    @include media-breakpoint-down(md) {
        height:auto;
    }
}
.label-color {
    line-height: 1;
    margin-right: $spacer/2;
}
.product-variants-item .color {
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
    @include transition-fast;
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
    border-color: $colorbox-border-color;
}

.tax-shipping-delivery-label, .delivery-information {
    font-size: $small-font-size;
    color: $gray-500;
}

.product-information {
    margin-top: $spacer;
}

.product-description {
    ul {
        padding-left: $spacer*1.25;
        padding-bottom: $spacer;
    }
}

.product-description, #category-description, #category-description-full, .sdsarticle-des, .page-content {
    font-size:$font-size-base;
    table {
        max-width: 100%;
        width: 100%;
      }
    h2, h3 {
        font-weight: $weight-bold;
        font-size: $font-size-base*1.25;
        margin-bottom: $spacer/2;
        margin-top: $spacer*1.75;
      }

    a {
        font-weight: $weight-bold;
    }
}

//product prices
.product__product-price {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.product__product-price .current-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $spacer * 0.5;
}
.price {
    color: $product-price-current-color;
    font-weight: $product-price-current-font-weight;
    font-family: $product-price-current-font-family;
    &.current-price-discount {
        color: $product-price-discount-color;
        font-size: $product-price-discount-font-size;
        font-weight: $product-price-discount-font-weight;
        font-family: $product-price-discount-font-family;
    }
}
.regular-price {
    font-family: $product-price-regular-font-family;
    text-decoration: line-through;
}
.discount {
    text-transform: uppercase;
    color: $white;
    font-weight: 600;
    display: inline-block;
    padding: 0.25 * $spacer;
}


//product add to cart
.btn-add-to-cart {
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
}
.attributes {
    gap: $spacer * 0.5;
}
//product tabs
.product-tabs {
    .nav-tabs {
        border: 0px;
        font-size: $font-size-base * 0.9;
    }
}

.product-features {
    .data-sheet {
        max-width: max-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:$spacer;
    }
    dt, dd {
        min-width: max-content;
        min-height: $spacer*3;
        align-items: center;
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        .data-sheet {
            grid-template-columns: 1fr;
        }

        dt, dd {
            min-height: auto;
        }

        dt {
            margin-top: $spacer*1.25;
        }
    }
}
.card--reassurance {
    box-shadow: $card-box-shadow;
}

// modal add to cart
.cart-content {
    padding: $spacer;
}

.checkout-summary {
    position: sticky;
    top: 10rem;
}

//product pack
.pack-product-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 $spacer;
    font-size: $font-size-sm;
    .thumb-mask {
        padding: 0 $spacer/2;
    }
}
.mask {
    width: 55px;
}
.thumb-mask {
    padding: $spacer 0;
}
// .pack-product__img {
//     border: 1px solid $border-color;
// }
.pack-product-name {
    flex-grow: 1;
    padding: 0 $spacer * 2;
}

.pack-product-price {
    padding-right: $spacer/2;
}
.pack-product-quantity {
    display: flex;
    align-items: center;
    align-self: stretch;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-left: $spacer;
}

//modal cart
.modal-cart__image {
    margin-right: $spacer;
    max-width: 200px;

    height: auto;
}
.modal-cart__name {
    color: $primary;
    font-weight: $font-weight-bold;
}
.product-total {
    font-weight: $font-weight-bold;
}
.cart-content {
    > p:not(:last-child) {
        margin-bottom: $spacer/2;
    }
}
@include media-breakpoint-mobile {
    .modal-cart__image {
        max-width: 120px;
    }
    .blockcart-modal {
        .divide-right {
            display: flex;
            justify-content: center;
        }
        .modal-footer {
            position: fixed;
            bottom: 0;
            background: $white;
            justify-content: center;
            left: 0;
            width: 100%;
            box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
        }
        .modal-body {
            margin-bottom: ($btn-padding-y * 2) +
                ($btn-font-size * $btn-line-height) + $modal-inner-padding * 2;
        }
    }
}
@include media-breakpoint-down(xs) {
    .modal-cart__image {
        max-width: 70px;
    }
}
//spinner add to cart
.btn-add-to-cart {
    .btn-add-to-cart__spinner {
        width: $spinner-width-addtocart;
        height: $spinner-height-addtocart;
        border-width: $spinner-borderwidth-addtocart;
        display: none;
    }
    &.is--loading {
        .btn-add-to-cart__spinner {
            display: inline-block;
        }
        .btn-add-to-cart__icon {
            display: none;
        }
    }
}

.products-selection {
    justify-content: space-between;
    align-items: center;
}
.pack-miniature-item {
    margin-bottom: $spacer / 2;
}
.ratio1_1 {
    display: block;
}
.pack-product-name {
    margin: 0 $spacer * 1.5;
    flex-grow: 1;
}
.product__card-img {
    position: relative;
}
.product__card-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.quantity__label {
    margin-bottom: 0;
}
.product-add-to-cart {
    .product-quantity {
        gap: $spacer;
    }
}
.product-customization {
    margin-bottom: $spacer * 1.5;
}
.product-discounts {
    margin-bottom: $spacer * 1.5;
}
.product__quantity {
    margin-bottom: 0;
    color: $gray-400;
}

.total__item-show {
    text-align: center;
    font-size: $font-size-sm;
    margin-top: $spacer;
}
.variant-links {
    display: flex;
    justify-content: center;
}
.product__download {
    text-transform: uppercase;
}
.card--address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.invoice__address {
    margin-top: $spacer;
}
.invoice-address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.pagination-summary {
    color: $gray-400;
}

@include media-breakpoint-mobile {
    #_mobile_search_filters .card-block {
        padding: 0 !important;
        box-shadow: none !important;
    }
}

.product-variants-item   {
    width: 100%;
}