.l-footer {
  background-color: $footer-bg;

  a {
    color: $text-muted;
    &:hover {
      color: $primary;
    }
  }

  p {

    line-height: 1.6;
}
}
.footer-container {
  margin-top: $footer-container-margin-top;
}

.footer-bar__left {
  column-gap: $spacer * 1.5;
  row-gap: $spacer;
}
.link-block {
  li {
    margin-bottom: $spacer * 0.75;
  }
  a {
    @include transition-fast;
  }
}

.block-contact .footer-contact-block {
  > a {
    @include border-radius($border-radius-lg);
    font-weight: $weight-medium;
    background-color: $gray-100;
    padding: ($spacer * 0.5) $spacer;
    display: inline-block;
    color: $off-black !important;
    @include transition-fast;
    &:hover,
    &:active,
    &:focus {
      box-shadow: $box-shadow-sm;
      transform: translateY(-($spacer * 0.125));
      color: $green !important;
    }
  }
  gap: $spacer * 0.5;
  margin-bottom: $spacer;
}

.footer__title {
  color: $black;
  &:visited,
  &:hover {
    color: inherit;
  }
  display: block;
  font-weight: $weight-medium;
  font-size: $font-size-base*1.25;
  margin-bottom: $spacer;
  &--mobile {
    display: none;
    margin-bottom: $spacer;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after {
      content: "\e313";
      font-family: "Material Icons";
      font-feature-settings: "liga" 1;
      position: absolute;
      right: $grid-gutter-width/2;
      @include transition(0.3s transform ease-in-out);
      font-size: $font-size-base * 1.5;
      font-weight: 100;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav {
  align-items: center;
}
.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}

@include media-breakpoint-mobile {
  .footer-row {
    .footer-logo,
    .link-block {
      flex-basis: 100%;
    }
    .block-contact {
      order: 1;
    }
  }
  .footer__title {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
  .footer-bar__right {
    flex-grow: 1;
  }
}
