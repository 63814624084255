header {
  .header-nav__round-icon {
    border-radius: $border-radius-lg;
    background: $tertiary;
    position: relative;
    @include transition(0.3s ease);
    z-index: 21;
    @include media-breakpoint-down(md) {
      font-size: 0;
    }
  }  

  /* Blockcart */

  .blockcart__count {
    @include transition(0.15s ease);

    &:empty {
      display: none;
    }
  }

  .inactive .blockcart__count {
    display: none;
  }

  .shopping-cart > .blockcart__count {
    display: none;
}

  .inactive .shopping-cart a {
    pointer-events: none;
  }

  @include media-breakpoint-tablet {

    // .blockcart {
    //   &.hovered {
    //     pointer-events: none;
    //   }
    //   .shopping-cart a {
    //     pointer-events: none;
    //   }
    // }
  }
}


@media (max-width:1700px) and (min-width:1601px){
  header .social {
    display:none
  }
}