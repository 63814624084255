

button.slick-arrow {
  width: 50px;
  height: 25px;
  position:relative;
  border: none;
  font-size: 0;
  background: url(/themes/purtex/assets/img/arrow-right.svg) center center no-repeat;
  &.slick-prev {
    background: url(/themes/purtex/assets/img/arrow-left.svg) center center no-repeat;
  }
}


.carousel-arrow-navigation {
  right: $spacer*5;
  bottom: $spacer*5;
  @include media-breakpoint-down(sm) {
    right: $spacer;
    bottom: $spacer;
    }
}

.carousel-image {
  border-radius: $btn-radius;
}


// @include media-breakpoint-down(xl) {
// #homeslider {
// .carousel-image{
//   min-height: $spacer*30;
//   max-height:$spacer*30
// }

// .slick-slide img{

//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
// }}

@include media-breakpoint-down(md) {
  #homeslider .slick-slide img{
    min-height: 40rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
}

#homeslider, #discount-slider, .product-miniature {
  .styled {
    background: $secondary;
    border-radius: $border-radius-lg;
    padding: $spacer;
    font-size: $small-font-size;
    max-width: max-content;
  }
  

  ul.slick-dots {
    display: flex!important;}

}

.styled.doprava-zdarma {
  position: relative;
  &::after {
    content: "doprava zdarma";
    position: absolute;
    right: -$spacer*15;
    background: url(/themes/purtex/assets/img/doprava-zdarma-purtex.svg) no-repeat;
    padding: $spacer/2 $spacer*2.75;
    background-size: contain;
    bottom: $spacer/2;    
}

    &::before{
      content: "+";
      position: absolute;
      right: -$spacer*2;
      bottom:$spacer/2;
      font-size:$font-size-base*2}
}

@include media-breakpoint-down(xl) {
  #discount-slider .styled {
    &:after {
      position: relative;
      left: 0;
      bottom: 0;
      display: block;
      margin-top: $spacer*2
    }
    &::before {
      display: none;
    }
  }
} 

#homeslider .styled {
  background: $secondary-on-dark;
  &::before, &::after{
    filter: brightness(10.5) grayscale(1) contrast(3);
  }

  @include media-breakpoint-down(sm) {
    &:after {
      position: relative;
      left: 0;
      bottom: 0;
      display: block;
      margin-top: $spacer*2
    }
    &::before {
      display: none;
    }
  }
}


svg#slider-shape {
  height: 100%;
  left: $spacer*4;
  z-index: 99999;
}
.slider-content {
  color:$white;
  top: 0;
  min-height: 100%;
  width: calc(50% - #{$spacer*7});
  left: $spacer*7;
  @include media-breakpoint-down(xl) {
    width: calc(50% - #{$spacer*3});
    left: $spacer*3;
  }
  @include media-breakpoint-down(md) {
    left: 0;
    width: 100%;
    &::before {
      content: "";
      background: linear-gradient(125deg, $primary,  transparentize($primary, 0.5));
      position: absolute;
      left:0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
        }
  }
  * {
    z-index: 99999;
  }
  h1, h2, p.title {
    font-size: clamp($spacer*1.75, 3vW, #{$display2-size});
    line-height: 1.2;
    margin-bottom: $spacer/2;
  }
}