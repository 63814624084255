@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap');
body {
    background-color: $backround-color;
    font-family: Inter, 'Arial Narrow Bold', sans-serif;
    line-height: 1.6;
}
html {
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
}
sup {
    top: -0.3em;
}

.l-wrapper {
    @if $enable-fluid-layout {
        @extend .container-fluid;
    } @else {
        @include make-container();
    }
    &--boxed {
        @include make-container();
    }

    max-width: 1800px;
    margin-left: auto;
    margin-right: auto
}

.page-wrapper,
.card-block {
    @extend .card;
}

.page-header {
    @extend .card-header;
    h1 {
        margin-bottom: 0;
    }
}
.card-block {
    @extend .card-body;
}
.card-block {
    box-shadow: $card-box-shadow;
}
.page-footer {
    @extend .card-footer;
    &:empty {
        display: none;
    }
}

.left-column > div,
.right-column > div {
    margin-bottom: $spacer;
}

/* MISC */
ul {
    list-style: none;
    padding-left: 0;
}
h1,
.h1 {
    text-transform: $h1-text-transform;
}
.form-group.form-group_forgot-password {
    margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin {
    @include border-radius($border-radius-sm);
    border: none !important;
    height: 100%;
    overflow: hidden;
    > input {
        max-width: 60px;
        border: none;
        box-shadow: none;
        text-align: center;
        font-weight: 700;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .form-control {
      box-shadow: none !important;
      outline: none !important;
    }
    .input-group-btn {
        position: relative;
        z-index: 2;
        color: $input-color;
        background-color: $white;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
        &:before,
        &:after {
            content: "";
            width: 100%;
            position: absolute;
            z-index: -1;
            bottom: 0;
            @include transition(0.15s ease-in-out);
        }
        // &:before {
        //     height: 100%;
        //     background-color: $white;
        //     transform-origin: right;
        // }
        // &:after {
        //     height: 100%;
        //     background-color: $primary;
        //     transform: scaleX(0);
        // }
        &:first-child:after {
            transform-origin: left;
        }
        &:last-child:after {
            transform-origin: right;
        }
        &:hover:after,
        &:focus:after,
        &:active:after {
            transform: scaleX(1);
        }
        button {
            @include transition(0.15s ease);
            background-color: transparent;
            font-weight: $font-weight-bold;
            &:hover,
            &:active,
            &:focus {
                color: $primary;
            }
        }
    }
    .input-group-btn {
        display: flex;
        &:first-child {
            margin-right: -$input-border-width;
        }
        &:last-child {
            margin-left: -$input-border-width;
        }
    }
}

.input-group > .form-control {
    height: auto;
}

/* minimizing reflows and avoiding page jumps */

.rc {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @include transition(background 150ms);
    &:after {
        @extend .spinner-border;
        content: "";
        border-color: $gray-200;
        position: absolute;
        top: calc(50% - #{($spinner-height/2)});
        left: calc(50% - #{($spinner-width/2)});
        z-index: 2;
        border-right-color: transparent;
    }
    &--lazyload:after {
        display: none;
    }
}

.rc > * {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.rc > a {
    display: block;
}
.ratio2_1 {
    padding-top: 50%;
}
.ratio1_2 {
    padding-top: 200%;
}
.ratio4_3 {
    padding-top: 75%;
}
.ratio16_9 {
    padding-top: percentage(9/16);
}
.ratio1_1 {
    padding-top: 100%;
}
.ratio3_2 {
    padding-top: percentage(1-2/3);
}

/* Collapse icons */
.icon-collapse {
    @include transition(transform 0.4s ease);
}
.icon-collapse[aria-expanded="true"] {
    transform: rotateZ(180deg);
}

/* Checkout step */
.order-checkout .card-body {
    padding-bottom: 0;
}
.step-edit[aria-expanded="true"] {
    display: none;
}
.step-title {
    margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
    display: inline-block;
}
.-current .step-title .done {
    visibility: hidden;
}
.-current .card-header {
    background-color: $white;
}

.accordion {
    .card-body .card {
        border: 1px solid $card-border-color;
        @include transition(border 0.1s linear);
    }
    .card-body .card-header {
        text-align: center;
    }
    .selected .card {
        border-color: $success;
        border-width: 2px;
    }
}
// Personnal info
.nav-tabs-info {
    font-size: $font-size-base * 1.1;
    .active {
        font-weight: bold;
    }
}

// Addresses

.address-selector {
    .card {
        @include transition(border 0.1s linear);
    }
    .card-header,
    .card-body {
        cursor: pointer;
    }
}
.card-body_add-address {
    padding: $card-spacer-y $card-spacer-x;
    .material-icons {
        opacity: 0.8;
    }
}
// Shipping
.delivery-option {

}
.carrier-extra-content:not(:empty) {
    margin-bottom: $spacer * 2;
    &:empty {
        display: none;
    }
}
.carrier-name {
    font-weight: $font-weight-bold;
}
.carrier-price {
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
}
// Order list final recap
.order-line:not(:last-child) {
    margin-bottom: $spacer/2;
}
// .order-confirmation-table {
//     padding: $spacer;
//     border: 3px solid $card-border-color;
// }

// Forms
custom-file-label.selected:after {
    content: "" !important;
}
.label {
    @extend label;
}
// Nav tabs
.nav-tabs {
    .nav-link {
        color: $gray-500;
        font-weight: 700;
    }
    .nav-link.active,
    .nav-item.show .nav-link {
        border-bottom-width: 3px;
    }
}
.tab-content {
    padding-top: $spacer;
    .product-reference {
        display: none;
    }
}
//products-section-title
.products-section-title {
    text-transform: $section-title-text-transform;
    text-align: $section-title-text-align;
    display: block;
    margin-bottom: $section-title-margin-bottom;
    font-family: $section-title-font-family;
    color: $section-title-color;
    font-size: $section-title-size;
    font-weight: $section-title-font-weight;
}

// Home content spacing
.page-home > * {
    margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
    .modal-footer {
        flex-direction: column;
        flex-direction: column-reverse;
        & > .btn {
            margin: 0;
            width: 100%;
        }
        & .btn:not(:last-child) {
            margin-top: $spacer/2;
        }
    }
}

.section-title {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-mobile {
        flex-direction: column;
        align-items: flex-start;
    }
    h2 {
        font-size: $display3-size;
    }
}

.nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        display: none;
    }
    .nav-link {
        white-space: nowrap;
    }
}

// Forms
main > .notifications-container {
    @extend .l-wrapper;
}
.notifications-container {
    ul {
        margin-bottom: 0;
    }
}
.js-invalid-feedback-browser:empty {
    display: none;
}
.form-group {
    label,
    .label {
        small,
        .small {
            font-size: 0.75 * $font-size-base;
        }
    }
}
@include media-breakpoint-desktop {
    // .page-content,
    // .checkout-form,
    // .page-wrapper--order-confirmation {
    //     form {
    //         width: 75%;
    //         max-width: 600px;
    //         margin: 0 auto;
    //     }
    // }
    .page-wrapper--order-detail {
        form {
            margin: unset;
        }
    }
}
.form-footer {
    margin-top: $spacer;
}

// Badge order history
.badge.bright {
    color: $white;
}

.icon-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"] {
    &::-ms-clear { display: none; width : 0; height: 0; }
    &::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
}
